<template>
  <transition name="fade">
    <div
      v-if="checkedList.length"
      class="create-group-row flex w-full justify-content-between"
    >
      <div class="create-group-block flex align-item-center">
        <custom-input
          placeholder="Название"
          name="name"
          :inputWidth="97"
          v-model="groupName"
        />
        <button @click="createGroup" class="btn btn-accent">
          Создать группу
        </button>
      </div>

      <div class="selected-count">Выделено: {{ checkedList.length }}</div>
      <div v-if="isLoading" class="form-preloader">
        <preloader />
      </div>
    </div>
  </transition>
</template>

<script>
import { computed, ref, toRef } from "@vue/runtime-core";
import { useStore } from "vuex";
import useErrors from "../../mixins/useErrors";
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import standartError from "../../mixins/standartError";
import { useRouter } from "vue-router";
import Preloader from "@/components/Technical/Preloader.vue";
import { notify } from "@kyvg/vue3-notification";
export default {
  components: { CustomInput, Preloader },
  name: "create-group-row",
  props: {
    checkedList: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const store = useStore(),
      router = useRouter(),
      { setErrors, clearCustomErrors } = useErrors();
    const profile = computed(() => store.state.profile.profile),
      group = computed(() => store.state.group.group.group);
    const groupName = ref(""),
      users = toRef(props, "checkedList"),
      isLoading = ref(false);
    const createGroup = () => {
      if (!groupName.value || !users.value.length) {
        notify({
          type: "warning",
          title: "Название группы должно быть заполнено",
        });
        return;
      }
      isLoading.value = true;
      store
        .dispatch("group/createGroup", {
          name: groupName.value,
          owner_id: Number(profile.value.id),
        })
        .then(() => {
          store
            .dispatch("group/updateGroupUsers", {
              id: group.value.id,
              users: users.value.map((user) => Number(user)),
            })
            .then(() => router.push(`/group/${group.value.id}`));
        })
        .catch(standartError(setErrors, clearCustomErrors))
        .finally(() => (isLoading.value = false));
    };

    return {
      createGroup,
      groupName,
      isLoading,
    };
  },
};
</script>

<style scoped lang="scss">
.create-group-row {
  padding: 10px 0;
  margin: 10px 0;
  border-top: 1px solid var(--cream-color);
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  .selected-count {
    margin-top: 10px;
  }

  @media (min-width: 900px) {
    flex-direction: row;
    align-items: center;

    .selected-count {
      margin-top: 0;
    }
  }

  @media (min-width: 1198px) {
    .form-group {
      min-width: 500px;
    }
  }
}
</style>